.login-logo{
  z-index: 2;
  background-color: #cfcfc4;
  height: 120px;
  width: 120px;
  margin-top: 4%;
  margin-left: 45%;
  text-align: center;
  border-radius: 50%;
  padding:19px;
}

.login-input {
  align-content: center;
  width: 340px;
}

.login-input-container {
  display: flex;
  justify-content: center;
}

.login-input-container p {
  width: 348px;
  display: flex;
  justify-content: left;
  height: 14px;
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  text-align: left;
  padding-left: 5px;
}

.login-input-container-title {
  display: flex;
  justify-content: center;
}

.login-input-container-title p {
  width: 348px;
  display: flex;
  justify-content: left;
  height: 25px;
  font-size: 21.5px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
  text-align: left;
  color: #1c2243;
  padding-left: 5px;
  margin-bottom: 33px;
}

.login-button-container{
  width: 348px;
  display: flex;
  justify-content: flex-end;
  margin-top: 63px;
}

.login-button {
  width: 89px !important;
  height: 34px !important;
  border-radius: 2px !important;
  background-color: #0082ed !important;
  color: white !important;
  text-transform: none !important;
}

.login-button label{
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.21;
  letter-spacing: normal;
}