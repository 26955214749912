// COLORS
$background: #fafbfc;
$foreground: #ffffff;
$background-row-1: #f1f4f7;

$primary: #419bf9;
$secondary: #d43030;

$font-color-black: #1c2243;
$font-color-black-2: #292929;
$font-color-black-3: #3b3b3b;
$font-color-black-4: #353535;
$font-color-gray: #7a7e8f;
$font-color-gray-2: #989898;
$font-color-gray-3: #5b5b5b;
$font-color-gray-4: #adb6c1;
$font-color-light: #8da6be;
$font-color-more-light: #7c97b2;
$font-color-search: #646464;
$font-color-link: #0067ff;

$icon-top-header: #7e7e7e;
$icon-gunmetal: #5e6667;
$icon-light: #8da6be;
$icon_loupe: #a1b4b6;
$v-separator-color: #c5c5c5;

$radio-btn-on: #93d289;
$radio-btn-off: #c6c6c6;

$bg-selected-vuln-row: #6f6f6f;
$border-gray: #e8e8e8;
$color-confirmed-box: #a581eb;

$black: #000000;
$white: #ffffff;
$disabled: #ddd;

$color-critical: #a4247a;
$color-high: #ea3158;
$color-medium: #f59220;
$color-low: #88cc3f;
$color-informational: #82B8E5;
$color-unclassified: #989898;

$opened-color: #a4247a !important;
$closed-color: #90a9c0 !important;
$reopen-color: #e26464;
$riskacepted-color: #88cc3f;


// DIMENSIONS
$container-padding-left: 30px;
$container-padding-right: 30px;
$container-padding-top: 27px;
$container-padding-bottom: 12px;

$table-hover: rgb(237, 242, 247);
$table-preview-selected: #6f6f6f;


