@import "Common/styles/rules";
@import "Common/styles/variables";
@import "Common/styles/componentes";

*{
  margin: 0;
  padding: 0;
}

body {
  background: $background !important;
  color: $font-color-black;
  height: 100%;
  width: 100%;

  &:not(.editor-toolbar) {
    font-family: 'Sequel Sans', sans-serif !important;
  }
}

.react-contextmenu-wrapper{
  font-family: 'Sequel Sans', sans-serif !important;
}


html {
  height: 100%;
}

#root {
  height: 100%;
  background-color: #dde6ee;
}

.h-100{
  height: 100%;
}

p { 
  margin-bottom: 0px !important;
}
