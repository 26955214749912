@import "Common/styles/variables.scss";
@import "Common/styles/componentes";

.full-opened-view{
  height: 100%;
  width: calc(100% - 226px - 675px - 291px - 24px);
}

.filter-opened-view{
  height: 100%;
  width: calc(100% - 226px - 291px - 24px);
}

.filter-row{
  cursor: pointer;

  p{
    margin-left: 10px;
  }

  &:hover{
    background-color: #f1f4f7;
  }

  .selected-filter {
    border-radius: 3px;
    background-color: #f1f4f7;
    font-weight: 600;
  }
  .custom-filter-options{
    display: none;

    svg{
      width: unset!important;
      height: unset!important;
      visibility: hidden;
    }
  }

  div {
    -webkit-appearance: unset;
  }
}

.filter-row:hover{ 
  .custom-filter-options{
    display: inline;
    svg{
      width: unset!important;
      height: unset!important;
      visibility: visible;
    }
  }
}

.accordion{
  width: 100%;
  text-align: left;
  text-decoration: none;
}

.custom-filters-section {
  padding: 25px 23px 14px 23px!important;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 291px !important;
  height: 556px;
  overflow-y: auto;
  z-index: 200;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 30px 0 rgba(110, 110, 110, 0.08);
  position: relative;
  top: -25px;

  .h-separator{
    width: 258px;
    height: 1px;
    border-top: dashed 1px #c8d7e5;
    margin-left: -7px;
    margin-top: 27px;
    margin-bottom: 21px;
  }

  .toogle-box{
    width: 118px;
    height: 32px;
    opacity: 0.6;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px #dbdbdb;
    background-color: #ffffff;
    @extend .text-black-14;
    font-weight: 500;
    color: #63758d;
    padding-top:7px;
    cursor: pointer;

    &:hover{
      background-color: #e2ebf3;
      border: solid 1px #dbdbdb;      
    }

    &.activated{
      border: solid 1px #dbdbdb;
      background-color: #686a76;
      color: white!important;
      font-weight: bold;
    }
  }

  .error-notifier {
    font-size: 14px;
    font-weight: 600;
    background-color: #ca3d3c;
    color: #fff;
    border-radius: 4px;
    margin-top: 21px;
    padding: 3px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.06);
    opacity: 0.8;
  }

  .btns-container {
    position: absolute;
    bottom: 14px;
    left: 78px;
}

}

.filters-container {
  background-color: $background;
  box-shadow: none;
  overflow-y: auto;
  overflow-x: hidden;
  width: 280px;
  height: 100%;
  margin-right: 6px
}

.filters-container-colapsed{
  width: 23px;
}

.filters-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #aaa;
}

.filters-container::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
}

.color-unclassified {
  color: $color-unclassified !important;
}
.color-unclassified:after {
  color: $color-unclassified !important;
}
.color-unclassified:before {
  color: $color-unclassified !important;
}

.color-informational {
  color: $color-informational !important;
}
.color-informational:after {
  color: black !important;
}
.color-informational:before {
  color: black !important;
}

.color-low {
  color: $color-low !important;
}
.color-low:after {
  color: black !important;
}
.color-low:before {
  color: black !important;
}

.color-medium {
  color: $color-medium !important;
}
.color-medium:after {
  color: black !important;
}
.color-medium:before {
  color: black !important;
}

.color-high {
  color: $color-high !important;
}
.color-high:after {
  color: black !important;
}
.color-high:before {
  color: black !important;
}

.color-critical {
  color: $color-critical !important;
}
.color-critical:after {
  color: black !important;
}
.color-critical:before {
  color: black !important;
}

.color-opened {
  color: $closed-color;
}
.color-closed {
  color: $closed-color;
}

.center-text{
  text-align: center !important;
}

.zindex{
  z-index: 99;
}

.vulnerability-detail{
  max-width: 675px;
  background-color: $background;
  box-shadow: none;
  overflow-y: hidden;
  overflow-x: hidden;
  position: absolute;
  right: 0;
  z-index: 1;
  width: 100%;
}

.manage-detail{
  padding-left: 30px;
  padding-right: 30px;
}

.evidence-name{
  cursor: pointer;
  color: cornflowerblue;
}

.evidence-container{
  text-align: center;
}

.attachement-error{
  margin-top: 8px;
  font-size: 11px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 30% !important;
  padding: 10px;
}

.filter-row svg{
  vertical-align: -webkit-baseline-middle !important;
  width: 12%;
  height: 12%;
  cursor: pointer;
}

.preview-select{
  padding-left: 30% !important;
}

.max-width{
  width: 100%
}

.column_name{
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  color: #1c2243;
  //max-width: unset;
}

.column_target{
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
}

.severity-letter{
  font-size: 9px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  line-height: normal;
}

// NEW STYLES
.action-dropdown{
  .dropdown-menu{
    transform: translate3d(-140px, 28px, 0px)!important;
    box-shadow: 2px 2px 30px 0 #d5dee6;
    background-color: #ffffff;
    border: none;
    padding-top: 18px;
    padding-bottom: 18px;

    .dropdown-item{
      @extend .text-black-14;
      padding: 8px 0 8px 26px!important;

      &:hover{
        background-color: $background-row-1;
      }
    }

  }
}

.radio-vuln-detail{
  height: 32px;

  .radio-text {
    position: relative;
    bottom: 2px;
    margin-left: 12px;
  }
}

.btn-close-prev{
  cursor: pointer;
  width: 37px;
  height: 37px;
  margin-left: auto;
  padding: 5px 0;
  bottom: 4px;
  position: relative;
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &:hover{
    background-color: #eeeeee;
    border-radius: 50%;
  }
}


.setting-icon{
  width: 20px!important;
  height: 20px!important;
}

.react-contextmenu-wrapper{
  height: 100%;
}

.icon-container-hover{
  -webkit-appearance: none;

  .react-contextmenu-wrapper{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    padding: 0px 4px 0 0;
    &:hover{
      background-color: #95acc2;
        .icon-more{
          color: white !important;
        }

        .icon-tag{
          color: white !important;
        }
    }
    &:active{
      background-color: #95acc2;
      color: white;
    }
  }
}

.trash-container-hover{  
  width: 28px;
  height: 28px;
  border-radius: 50%;
  padding: 0 5px;
  cursor: pointer;

  &.disabled{
    cursor: default;
    &:hover{
      background-color: transparent;
    }
  }

  &:hover{
    background-color: #eeeeee;

  }

  .icon-trash{
    font-size: 18px!important;
    &.disabled{
      cursor: default;
      color: #aaa
    }
  }
}


.manage_detail_host{
  padding-left: 8px;
  margin-bottom: 5px;
}

.custom-manage-filter-icon{
  position: relative;
  top: 5px;
  width: 16px;
  height: auto;  
}

.e-container{
  width: 140px;
  height: 134px;
  border-radius: 3px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
  padding: 7px;
  cursor: pointer;

  &.default{
    padding: 24px 25px 26px 23px;
  }

  &:hover{
    background-color: #eeeeee;
  }
}
.icon-container-hover{
  width: 28px;
  height: 28px;
  border-radius: 50%;
  padding: 0px 4px 0 0;
  &:hover{
    background-color: #95acc2;
    border-radius: 50%;
      
    .icon-tag{
      color: white !important;
    }
  }
  
  &:active{
    background-color: #95acc2;
    color: white;
  }
}

.d-evidence-position{
  top: -6px;
  right: -6px;
  position: relative;
}

.icon-modal-container {
  position: absolute;
  top: -24px;
  right: -34px;
  width: 32px;
  height: 32px;
  border: solid 1px #ffffff;
  background-color: #eeeeee;
  border-radius: 50%;
  padding: 2px 7px;
  cursor: pointer;
}

.no-custom-field{
  margin-top: 90px;
}

.vulnerability-detail .CodeMirror{
  height: 180px !important;
}

.vulnerability-detail .CodeMirror-scroll{
  min-height: 180px !important;
}

.editor-statusbar {
  display: none;
}

.context-menu-manage{
  max-height: 434px;
  width: 244px;
  box-shadow: 2px 2px 10px 0 #d5dee6;
}

.context-menu-manage-reduce{
  height:  210px;
  width: 244px;
  box-shadow: 2px 2px 10px 0 #d5dee6;
}

.context-menu-manage svg{
  color: #979aaf;
}

.context-menu-manage-reduce svg{
  color: #979aaf;
}

.context-menu-bottom-separator{
  width: 210px;
  height: 2px;
  border: solid 1px #d8e0e7;
  margin-left: 16px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.delete-context-menu{
  color: #ea3158;
}

.delete-context-menu svg{
  color: #ea3158 !important;
}

.react-contextmenu-submenu .react-contextmenu{
  width: 191px;
  height: 232px;
  border-radius: 3px;
  box-shadow: 2px 2px 10px 0 rgb(213,222,230);
  border: solid 1px #ffffff;
  background-color: #ffffff;
}

.react-contextmenu-submenu .react-contextmenu .react-contextmenu-item{
  padding-bottom: 7px;
  height: 36px;
  font-weight: 300;
}

.react-contextmenu-submenu-status{
  height: 155px !important;
  font-weight: 300;
}

.react-contextmenu-submenu-filter-by{
  height: 190px !important;
}

nav:focus,nav:active {
  outline: none !important;
  box-shadow: none;
}

.more-options-active .react-contextmenu-wrapper{
  background-color: #95acc2 !important;
  border-radius: 50%;
}

.more-options-active .icon-more{
  color: white !important;
}

.more-options-active:hover {
  background-color: #95acc2 !important;
  border-radius: 50%;
}

.more-options-active-unset{
  background-color: unset;
  color: unset;
  border-radius: unset;
}

.confirmed-text{
  font-size: 13.5px;
  font-weight: 300;
  letter-spacing: normal;
  color: #63758d;
  margin-left: 5px;
}

.react-contextmenu-submenu-custom-field{
  height: fit-content !important;
  overflow-x: hidden;

  p {
    font-weight: 300 !important;
  }
}

.sub-menu-edit{
  height: fit-content !important;
  width: 250px !important;
}

.height-fit-content{
  height: fit-content !important;
}

.acvity-feed-tool-container-selected{
  width: fit-content;
  border-radius: 3px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.06);
  background-color: #686a76;
  height: 29px;
}

.activity-feed-tool-selected{
  padding-top: 3px;
  padding-right: 11px;
  padding-left: 11px;
  margin-bottom: 0 !important;
  overflow-y: hidden;
  color:white;
  font-weight: normal;
  font-size: 14px;
}

.btn-remove-list-element-massive{
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding-right: 7px;
  padding-top: 2px;
  position: relative;

  &:hover{
    background-color: #eeeeee;
    border-radius: 50%;
  }
}
.add-remove-clumns-manage-container{
  width: 518px;
  height: 628px;
}

.columns-to-add-container{
  width: 256px;
  background-color: #f1f4f7;
  height: 629px;
  display: inline-block;
  padding-top: 79px;
  padding-bottom: 28px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
}

.columns-to-add-container div{
  -webkit-appearance: none !important;
}

.columns-added-container{
  width: 262px;
  height: 629px;
  display: inline-block;
  padding-left: 20px;
  padding-top: 24px;
}

.column-field{
  margin-bottom: 5px !important;
  font-size: 12px;
  font-weight: 400;
  color: #1c2243;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
}

.column-field:hover{
  background-color: #e4e9ee; 
}

.checkbox-field-selection{
  width: 20px;
  height: 20px;
  object-fit: contain;
  color: #d5e1ec;
  float: right;
  margin-top: 3px;
}

.column-management-icon{
  width: 14px;
  height: 11px;
  object-fit: contain;
  margin-right: 15px;
}

.columns-added-title{
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  letter-spacing: normal;
  color: #1c2243;
}

.columns-draggable-container{
  height: 470px;
  overflow: scroll;
  -webkit-appearance: none;
}

.columns-draggable-container li{
  height: 30px;
  width: 210px;
  border-bottom: 1px dashed #c5c5c5 ;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.21;
  letter-spacing: normal;
  color: #1c2243;
}

.columns-draggable-container li:hover{
  background-color: #f1f4f7;
}

.columns-draggable-container li p{
 text-decoration: none;
 color: #1c2243 ;
 width: 150px;
}

.checkbox-field-selected{
  width: 17px;
  height: 15px;
  object-fit: contain;
  background-color: #000000;
  float: right;
  margin-top: 8px;
  margin-right: 12px;
}

li[draggable=true]{
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  background-color: #7493b1 !important;
  color: #ffffff;
  padding-left: 14px;
  border-bottom: 0px;
  cursor: pointer;
}

li[draggable=true] p{
  color: #ffffff ;
  width: 150px;
}

li[draggable=true] svg{
  color: #ffffff;
}

li[draggable=true] .draggable-icon{
  display: inline-block;
  color: #ffffff !important;
  margin-left: 0px !important;
}

li:hover .draggable-icon{
  display: inline-block;
  color: #1c2243;
  margin-left: 14px;
}

.drag-drop-text{
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: '#95acc2';
  width: 201px;
}

.columns-manager .react-contextmenu-wrapper{
  padding: 1px 6px 0 0;
}

.border-draggable{
  border: 1px dashed blue !important;
}

.draggable-icon{
  display: none;
  height: 10px;
  margin-bottom: 1px;
}

.columns-draggable-container li {
  cursor: pointer;
}

.column-field .checked{
  display: none;
}

.column-field:hover .checked{
  display: inline-block !important;
  color: #1c2243;
}

.column-field:hover .checkbox-field-selection{
  display: none;
}

.columns-draggable-container li .unchecked{
  display: none;
}

.context-menu-add-columns{
  box-shadow: 2px 2px 30px 0 rgba(0, 0, 0, 0.14);
}

.expandable-container{
  width: 17px !important;
}

.max-width-unset{
  max-width: unset !important;
}

.display-none{
  display: none !important;
}